import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { User } from '../../+client-admin/users/user.model'
import { Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  user$: Observable<User>;

  constructor(
    private http: HttpClient
  ) {
    console.log("SEAN CurrentUserService constructor")
  }

  getUser(token?:string):Promise<User> {
    if (!this.user$) {
      this.user$ = this.http.get<User>(`/api/user`).pipe(shareReplay(1));
      setTimeout(() => {
        this.user$ = null;
      }, 500000);
    }
    return this.user$.toPromise();
  }

  async findUserByEmail(email: string) {
    if (!email) {
      return null;
    }
    return this.http.get(`/api/user/${email.toLowerCase()}`);
  }

  async getPermissions() {
    const user: User = await this.getUser();
    return {
      'canEditBoardTemplate': user.superadmin || user.currentRole.role === 'Admin'
    }
  }

  inviteNewUser(user: any) {
    return this.http
      .post('/api/email/invite-user', user);
  }

  createNewUser(user: any) {
    return this.http
      .post('/api/user', user);
  }
}
