import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { TrayService } from './shared/trays/tray.service';
import { AuthService } from '@auth0/auth0-angular';

// Tell Typescript about this global
declare var ga: Function;
declare let $: any;

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  title = 'SlapFive';

  currentRoute: string;

  constructor(
    public location: Location,
    public router: Router,
    public trayService: TrayService,
    public auth: AuthService,
  ) {
    // Google Analytics; send pageview event on angular's
    //  NavigationEnd event.
    router.events.subscribe((event: Event) => {
      this.trayService.closeTray();
      // Send GA tracking on NavigationEnd event. You may wish to add other
      // logic here too or change which event to work with
      if (event instanceof NavigationEnd) {
        // When the route is '/', location.path actually returns ''.
        const newRoute = location.path() || '/';
        // If the route has changed, send the new route to analytics.
        if (this.currentRoute !== newRoute) {
          ga('send', 'pageview', newRoute);
          this.currentRoute = newRoute;
        }
      }
    });

  }


  ngOnInit(): void {
    console.log("SlapFive Application Started")
    // this.auth.localAuthSetup();
  }

}
