import { Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ListUsersGqlService } from '../user-gql/list-users-gql.service';

import { CurrentUserService } from '../../../shared/current-user/current-user.service';

import { Utils } from '../../../shared/utils/Utils';
import { ClientRole_User } from '../../clients/client-role.model';
import { User } from '../user.model';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InfiniteSelectConfig } from 'app/shared/infinite-select/infinite-select.component';
import { RequestUsersGqlService } from 'app/+client-admin/requests/requests-gql/request-users-list-gql.service';

// This is written here and not in a convenience service
//  so we can dyanimcally edit this GQL to tag different
//  entities with the same Mutation


@Component({
  selector: 's5-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss'],
})
export class UserPickerComponent implements OnInit {

  _user: User;
  get user() {return this.userConfig.value}
  @Input() set user(input) {
    if (input?.id) {
      this.userConfig.value = input;
    }
    
  };
  @Output() userChange: EventEmitter<User> = new EventEmitter<User>();
  @Input() showAvatar = true;
  @Input() showAvatarList = true;

  @ViewChild('labelTemplate') labelTemplate: TemplateRef<any>;
  @ViewChild('optionTemplate') optionTemplate: TemplateRef<any>;

  allUsers:User[];

  public userConfig: InfiniteSelectConfig = {
    entityName: 'User',
    key: 'id',
    bindLabel: 'name', // Customized
    placeholder: 'Select a user...',
    dropdownPosition: 'bottom',
    clearable: true,
    inputAttrs: {
      class: 'h-100'
    },
  }

  constructor(
    private listUsersGqlService: ListUsersGqlService,
    public requestUsersGqlService: RequestUsersGqlService,
    private currentUserService: CurrentUserService,
  ) {
  }

  async ngOnInit() {
    this.userConfig.value = this.user;
    const user = await this.currentUserService.getUser();console.log("UserPickerComponent ngOnInit user:", user)
    this.allUsers = Utils.usersForUsersClient(user);
    const client = user.currentRole.client;
    // this.userConfig.value = this.user;
    this.userConfig.variables = {clientId: client.id};
    
    this.userConfig.templates = {
      label: this.labelTemplate,
      option: this.optionTemplate
    }
  }


  compareTags(a:any, b:any) {
    return a.id === b.id;
  }

  onChange(user) {
    this.userChange.emit(user);
  }

}
