import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import cuid from 'cuid';

import { Tag } from '../tag.model';
import { CreateTagService } from '../tags-gql/create-tag.service';
import { UpdateTagService } from '../tags-gql/update-tag.service';
import { CurrentUserService } from 'app/shared/current-user/current-user.service';

import { DeleteTagGqlService } from '../tags-gql/delete-tag-gql.service';


@Component({
  selector: 's5-tags-editor',
  templateUrl: './tags-editor.component.html',
  styleUrls: ['./tags-editor.component.scss']
})
export class TagsEditorComponent implements OnInit {

  @Input() tag:Tag;
  @Output() tagChange: EventEmitter<Tag> = new EventEmitter<Tag>();

  constructor(
    private createTagService: CreateTagService,
    private updateTagService: UpdateTagService,
    private deleteTagService: DeleteTagGqlService,
    private userService: CurrentUserService
  ) { }


  ngOnInit(): void {
  }

  async save() {

    const tagToSave = {
      name: this.tag.name,
      id: this.tag.id,
      client_id: this.tag.client_id,
      description: this.tag.description,
      color: this.tag.color
    };

    if (tagToSave.id) {
      await this.updateTagService.mutate(tagToSave).toPromise()
    } else {
      const user = await this.userService.getUser();
      tagToSave.client_id = user.currentRole.client.id;

      tagToSave.id = cuid();

      await this.createTagService.mutate(tagToSave).toPromise();

      // effectively close the editor
      this.close();
    }
  }

  async delete(tag:Tag) {
    if (confirm("Are you sure you want to delete this tag?")) {
      await this.deleteTagService.mutate({ id: tag.id }).toPromise();
    }
  }

  close() {
    if (this.tag.id) {
      this.tag.inlineEdit = false;
    } else {
      // tag was deleted
      this.tag = undefined;
      this.tagChange.emit(this.tag);
    }
  }
}
