import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import cuid from 'cuid';

import { StrategicInitiativeViewGqlService, VIEW_STRATEGIC_INITITATIVES_QUERY_NAME } from '../gql/strategic-initative-view-gql.service';

import { StrategicInitiativeUpdateService } from '../gql/strategic-initiative-update.service';
import { StrategicInitiativeDeleteService } from '../gql/strategic-initiative-delete.service';
import { StrategicInitiativeCreateService } from '../gql/strategic-initiative-create.service';
import { StrategicInitiativeModel } from '../strategic-initiative.model';

import { LIST_STRATEGIC_INITITATIVES_QUERY_NAME } from '../gql/list-strategic-initiatives-gql.service';
import { Client } from 'app/+client-admin/clients/client.model';
import { Utils } from 'app/shared/utils/Utils';
import { CurrentUserService } from 'app/shared/current-user/current-user.service';


import { HeaderTitleService } from '../../nav/header-title.service';



@Component({
  selector: 's5-strategic-initiative-edit',
  templateUrl: './strategic-initiative-edit.component.html',
  styleUrls: ['./strategic-initiative-edit.component.scss']
})
export class StrategicInitiativeEditComponent {

  client: Client;
  id: string;
  duplicateName = false;
  strategicInitiative: StrategicInitiativeModel = {
    name: ''
  }


  isLoaded = false;
  isSaving = false;
  componentAlerts = []

  permissions = Utils.initRolePermissions();


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: CurrentUserService,
    private strategicInitiativeViewGqlService: StrategicInitiativeViewGqlService,
    private strategicInitiativeUpdateService: StrategicInitiativeUpdateService,
    private strategicInitiativeDeleteService: StrategicInitiativeDeleteService,
    private strategicInitiativeCreateService: StrategicInitiativeCreateService,
    public headerTitle: HeaderTitleService,
  ) {

  }

  async ngOnInit() {

    this.headerTitle.changeTitle('Strategic Initiatives: Edit');

    this.id = this.route.snapshot.params.id;
    this.permissions = await Utils.getRolePermissions(this.userService);
    if (!this.permissions.strategic_initiative_view) {
      this.router.navigate(['/']);
    } else {
      this.loadData();
    }
  }

  loadData() {
    this.strategicInitiativeViewGqlService.watch({ id: this.id || '1234567' }).valueChanges.subscribe((resp: any) => {
      if (resp?.data) {
        const data = JSON.parse(JSON.stringify(resp.data));
        if (data.strategicInitiative) {
          this.strategicInitiative = data.strategicInitiative;
        }
        this.client = data.Client[0];
        this.isLoaded = true;
      }
    })
  }

  checkName(event, form?) {
    const name = this.strategicInitiative.name.trim();
    if (name) {
      this.duplicateName = !!this.client.strategicInitiatives.find((si) => {
        const duplicate = name.toLowerCase();
        const check = si.name.trim().toLowerCase();
        return si.id !== this.strategicInitiative.id && duplicate === check;
      });
    }
  }

  saveInitiative() {
    this.isSaving = true;
    const vars = {
      id: this.strategicInitiative.id,
      name: this.strategicInitiative.name,
      color: this.strategicInitiative.color,
      description: this.strategicInitiative.description,
      executiveSponsor: this.strategicInitiative.executiveSponsor,
      goal: this.strategicInitiative.goal,
      influence: this.strategicInitiative.influence,
      startDt: this.strategicInitiative.startDt,
      endDt: this.strategicInitiative.endDt
    }

    if (this.strategicInitiative.owner) {
      vars['ownerId'] = this.strategicInitiative.owner.id;
    }

    if (this.strategicInitiative.id) {
      this.strategicInitiativeUpdateService.mutate(vars, { refetchQueries: [VIEW_STRATEGIC_INITITATIVES_QUERY_NAME], awaitRefetchQueries: true}).subscribe(data => {
        this.isSaving = false;
        this.returnToList();
      })
    } else {
      vars.id = cuid();
      this.strategicInitiativeCreateService.mutate(vars, { refetchQueries: [VIEW_STRATEGIC_INITITATIVES_QUERY_NAME], awaitRefetchQueries:true}).subscribe(data => {
        this.isSaving = false;
        this.returnToList()
      })
    }

  }


  deleteStrategicInitiative() {
      if (confirm('Are you sure you want to delete this Strategic Initiative?')) {
        this.strategicInitiativeDeleteService.mutate({ id: this.strategicInitiative.id }, { refetchQueries: [LIST_STRATEGIC_INITITATIVES_QUERY_NAME] })
          .subscribe(({ data }) => {
            this.componentAlerts.push({
              msg: 'Deleted',
              type: 'success',
              closable: true,
              dismissOnTimeout: 1500
            });
          });
      }
  }

  returnToList() {
    this.router.navigate(['/client/initiatives'])
  }

  closeAlert(componentAlert) {
    this.returnToList();
  }

  cancel() {
    this.returnToList();
  }

}
