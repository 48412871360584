import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { User } from '../user.model';
import { Query } from 'apollo-angular';
import { Client } from 'app/+client-admin/clients/client.model';

export const LIST_USERS_QUERY_NAME = 'ListUsersQuery';

interface ListUsersQueryResult {
  users: [User];
  client: [Client];
}

@Injectable({
  providedIn: 'root'
})
export class ListUsersGqlService extends Query<ListUsersQueryResult> {

  document = gql`
    query ${LIST_USERS_QUERY_NAME} {
      users: User {
        id
        name
        email
        auth0UserId
        phone
        currentRole {
          id
          role
        }
        clientRoles {
          id
          notifyNewPromptResponse
          notifyNewRequests
          allowRequestAssignment
          prefilterMembers
          auth0UserId
          ClientRole {
            id
            role
            client {
              id
            }
          }
        }
      }
      client: Client {
        id
        clientRoles {
          id
          role
          users {
            notifyNewPromptResponse
            notifyNewRequests
            allowRequestAssignment
            prefilterMembers
            auth0UserId
            user {
              id
              email
              name
            }
          }
        }
      }
    }`;
}
