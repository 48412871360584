import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ListStrategicInitiativedGqlService } from '../gql/list-strategic-initiatives-gql.service'
import { StrategicInitiativeModel } from '../strategic-initiative.model';
import { Utils } from 'app/shared/utils/Utils';
import { CurrentUserService } from 'app/shared/current-user/current-user.service';

import { Router } from '@angular/router';

import { HeaderTitleService } from '../../nav/header-title.service';


@Component({
  selector: 's5-strategic-initiatives-list',
  templateUrl: './strategic-initiatives-list.component.html',
  styleUrls: ['./strategic-initiatives-list.component.scss']
})
export class StrategicInitiativesListComponent {

  permissions = Utils.initRolePermissions();

  strategicInitiative$: Observable<StrategicInitiativeModel[]>;

  constructor(
    private listStrategicInitiativedGqlService: ListStrategicInitiativedGqlService,
    private userService: CurrentUserService,
    private router: Router,
    public headerTitle: HeaderTitleService
  ) {}

  async ngOnInit() {

    this.headerTitle.changeTitle('Strategic Initiatives');

    this.permissions = await Utils.getRolePermissions(this.userService);
    if (!this.permissions.strategic_initiative_view) {
      this.router.navigate(['/']);
    } else {
      this.strategicInitiative$ = this.listStrategicInitiativedGqlService.subscribe()
        .pipe(map(({ data }) => {
          return data.strategicInitiatives;
        }));
    }

  }

  ngOnDestroy() {
    this.strategicInitiative$ = null;
  }

}
