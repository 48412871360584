import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { Customer } from '../../+client-admin/members/member.model';
import { User } from '../../+client-admin/users/user.model';
import { environment } from '../../../environments/environment';
import { SentPrompt } from '../../+client-admin/prompts/sent-prompt.model';
import { Client } from '../../+client-admin/clients/client.model';
import { ClientRole_User } from '../../+client-admin/clients/client-role.model';
import cuid from 'cuid';
import { Company } from '../../+client-admin/companies/company.model';
import _ from 'lodash';
import { FulfillmentCustomer, Opportunity } from 'app/+client-admin/requests/request.model';
import { CompanyByNameGqlService } from 'app/+client-admin/companies/companies-gql/company-byName-gql.service'

import { Router } from '@angular/router';
import { CurrentUserService } from '../current-user/current-user.service';

export let companyByNameGqlService: CompanyByNameGqlService;
interface Suggestion {
  value: string;
  text: string;
}

// tslint:disable:member-ordering
export class Utils {
  public static ModalStoryFragment = gql`
    fragment ModalStoryFragment on Story {
      id
      type
      text
      transcript
      numericPrefix
      numericSuffix
      numericAnswer
      url
      media {
        id
        label
        mimeType
        url
      }
      customer {
        id
        name
        lname
        title
        email
        avatarImage {
          id
          mimeType
          url
        }
        company {
          id
          name
          industry
          size
          location
          image {
            id
            mimeType
            url
          }
        }
      }
      prompt {
        id
        title
      }
      client {
        id
        logoImage {
          url
          id
        }
        name
        primaryColor
        secondaryColor
      }
    }
  `;

  public static readonly colors = ['#a6cee3','#1f78b4','#b2df8a','#33a02c','#fb9a99','#e31a1c','#fdbf6f','#ff7f00','#cab2d6','#6a3d9a','#ffff99','#b15928']

  public static comparators = [
    { value: '_eq', text: 'equals', icon: 'fa-light fa-equals', types: ['string', 'number', 'date'] },
    { value: '_neq', text: 'does not equal', icon: 'fa-light fa-not-equal', types: ['string', 'number', 'date'] },
    { value: '_gt', text: 'is greater than', icon: 'fa-light fa-greater-than', types: ['number', 'date'] },
    { value: '_gte', text: 'is greater than or equal to', icon: 'fa-light fa-greater-than-equal', types: ['number', 'date'] },
    { value: '_lt', text: 'is less than', icon: 'fa-light fa-less-than', types: ['number', 'date'] },
    { value: '_lte', text: 'is less than or equal to', icon: 'fa-light fa-less-than-equal', types: ['number', 'date'] },
    { value: '_like', text: 'like', icon: 'fa-light fa-square', types: ['string'] },
    { value: '_ilike', text: 'like (case insensitive)', icon: 'fa-light fa-square-info', types: ['string'] },
    { value: '_in', text: 'is in', icon: 'fa-light fa-check-square', types: ['string', 'number'] },
    { value: '_nin', text: 'is not in', icon: 'fa-light fa-times-square', types: ['string', 'number'] },
    { value: '_null', text: 'is empty', icon: 'fa-light fa-circl', types: ['string', 'number'] },
    { value: '_nnull', text: 'is not empty', icon: 'fa-light fa-dot-circle', types: ['string', 'number'] },
  ];

  public static getComparator(comparator: string) {
    if (!comparator) {
      return Utils.comparators[0];
    }
    return Utils.comparators.find((c) => c.value === comparator);
  }

  public static getComparatorsForType(type: string) {
    return Utils.comparators.filter((c) => c.types.indexOf(type) > -1);

  }


  public static getBoardUrlNamePart(board): string {
    if (board.customUrl && board.customUrl.length > 0) {
      return Utils.toUrlSafeBoardName(board.customUrl);
    } else if (board.headline && board.headline.length > 0) {
      return Utils.toUrlSafeBoardName(board.headline);
    } else {
      return (Utils.toUrlSafeBoardName(board.name));
    }
  }

  public static toUrlSafeBoardName(boardName: string) {
    if (!boardName) {
      return '';
    }
    let urlSafe = '';
    let lastWasUnsafe = false;
    for (const c of boardName) {
      if ('abcdefghijklmnopqrstuvwxyz0123456789'.indexOf(c.toLowerCase()) > -1) {
        urlSafe += c;
        lastWasUnsafe = false;
      } else {
        if (!lastWasUnsafe) {
          urlSafe += '_';
        }
        lastWasUnsafe = true;
      }
    }
    return urlSafe;
  }

  public static copyToClipboard(val: string) {
    const ta: HTMLTextAreaElement = document.createElement('textarea');
    ta.style.display = 'hidden';
    document.body.appendChild(ta);
    ta.value = val;
    ta.select();
    ta.setSelectionRange(0, val.length);
    document.execCommand('copy');
    document.body.removeChild(ta);
  }

  public static getStorageValue(id: string) {
    try {
      const storage = window.localStorage;
      if (storage) {
        const val = storage.getItem(id);
        if (val && val.length > 0) {
          return val;
        }
      }
    } catch (e) {
      console.log('Local storage unavailable');
    }

    try {
      const storage = window.sessionStorage;
      if (storage) {
        const val = storage.getItem(id);
        if (val && val.length > 0) {
          return val;
        }
      }
    } catch (e) {
      console.log('Session storage unavailable');
    }
    return null;
  }

  public static setStorageValue(id: string, value: any) {
    try {
      const storage = window.localStorage;
      if (storage) {
        if (value) {
          storage.setItem(id, value);
        } else {
          storage.removeItem(id);
        }
        return 'Local Storage';
      }
    } catch (e) {
      console.log('Local storage unavailable');
    }

    try {
      const storage = window.sessionStorage;
      if (storage) {
        if (value) {
          storage.setItem(id, value);
        } else {
          storage.removeItem(id);
        }
        return 'Session Storage';
      }
    } catch (e) {
      console.log('Session storage unavailable');
    }
    return false;
  }

  public static getClientUUID() {
    let val = Cookie.get('client-uuid');
    if (val && val.length > 0) {
      return val;
    }

    val = this.getStorageValue('client-uuid');
    if (val && val.length > 0) {
      return val;
    }

    const uuid = this.generateUUID();
    Cookie.set('client-uuid', uuid, 999);
    this.setStorageValue('client-uuid', uuid);

    return uuid;
  }

  private static PageViewMutation = gql`
    mutation (
      $id: String!
      $boardId: String!
      $storyId: String
      $clientId: String!
      $userUuid: String!
      $shareId: String
      $browserSessionId: String
    ) {
      insert_PageView(
        objects: [{
          id: $id
          board_id: $boardId
          story_id: $storyId
          client_id: $clientId
          userUuid: $userUuid
          share_id: $shareId
          browserSession_id: $browserSessionId
        }]
      ) {
        affected_rows
      }
    }
  `;

  // Returns pageView id to be used as browser session id for subsequent page views.
  public static trackView(
    apollo: Apollo,
    boardId: string,
    clientId: string,
    storyId?: string,
    browserSessionId?: string
  ): string {
    const shareId = Utils.getQueryParameters().sid;
    const id = cuid();

    const vars = {
      id,
      boardId,
      browserSessionId,
      clientId,
      shareId,
      storyId,
      userUuid: Utils.getClientUUID(),
    };

    // run async for performance
    apollo.mutate<any>({
      mutation: Utils.PageViewMutation,
      variables: vars
    }).subscribe();
    return id;
  }

  // static trackView(clientId:string, boardId:string, storyId:string = undefined){
  //   if (!Utils.getCurrentUserId() && !!boardId){
  //     var shareId = Utils.getQueryParameters()["sid"];
  //     var pageView = {_id: Random.id(), clientId: clientId, boardId: boardId,
  //           storyId: storyId, userUuid: Utils.getClientUUID(), shareId: shareId, date: new Date()};
  //     PageViews.insert(pageView);
  //   }
  // }

  public static getQueryParameters(): any {
    return (document.location.search).replace(/(^\?)/, '').split('&').map(function (n) {
      return n = n.split('='), this[n[0]] = n[1], this;
    }.bind({}))[0];
  }

  // You must subscribe to stories in the component that calls this
  // static promptNameFromStoryId(storyId) {
  //   var story = Stories.findOne({_id: storyId});
  //   if (story) {
  //     var prompt = Prompts.findOne({_id: story.promptId});
  //     if (prompt){
  //       return prompt.title;
  //     } else {
  //       return "Prompt " + story.promptId + " not found for story " + storyId;
  //     }
  //   } else {
  //     return "Story " + storyId + " not found";
  //   }
  // }

  public static hasHadEmailShareSuccess() {
    return Cookie.get('email-share-success') === 'yes';
  }

  public static setEmailShareSuccess() {
    Cookie.set('email-share-success', 'yes', 999);
  }

  // static generateBranchLink(opts, callback){
  //   HTTP.post('https://api.branch.io/v1/url', {headers: {"Content-Type": "application/json"}}, callback);
  // }

  public static correctProtocol(url: string) {
    return url && url !== '' && 'https://' + url.replace(/http[s]*:\/\//gi, '');
  }

  public static stringOrBlank(str) {
    if (str) {
      return str;
    } else {
      return '';
    }
  }

  public static sortAlphaNumeric(list: (string | number | Suggestion)[], field = 'text') {
    list.sort((aIn, bIn) =>  {
      const a = typeof aIn === 'object' ? aIn[field] : aIn;
      const b = typeof bIn === 'object' ? bIn[field] : bIn;
      const aMatch = a.toString().match(/([\d,]+)|([^\d,]+)/g) || [];
      const bMatch = b.toString().match(/([\d,]+)|([^\d,]+)/g) || [];
      const min = Math.min(aMatch.length, bMatch.length);
      for (let i=0;i<min; i++) {
        const aNum = Number(aMatch[i].replace(',', ''));
        const bNum = Number(bMatch[i].replace(',', ''));
        if (aMatch[i] !== bMatch[i]) {
          if (aNum && bNum) {
            return aNum > bNum ? 1 : -1;
          } else if (aNum) {
            return 1;
          } else if (bNum) {
            return -1;
          } else {
            return aMatch[i] > bMatch[i] ? 1 : -1;
          }
        }
      }
      return 0;
    })
  }

  public static getLogLevel() {
    if (window.location.search.indexOf('log=debug') > -1) {
      return 'debug';
    }
    if (window.location.search.indexOf('log=info') > -1) {
      return 'info';
    }
    if (window.location.search.indexOf('log=error') > -1) {
      return 'error';
    }
    return environment.log_level;
  }

  public static logDebug(...args: any[]) {
    if (Utils.getLogLevel() === 'debug') {
      console.log.apply(console, args);
    }
  }

  public static logInfo(...args: any[]) {
    if (Utils.getLogLevel() === 'debug' || Utils.getLogLevel() === 'info') {
      console.log.apply(console, args);
    }
  }

  public static logError(...args: any[]) {
    console.log.apply(console, args);
  }

  public static getPermissions() {
    return [
      {
        value: 'Unknown',
        nice_name: 'Unknown',
        nice_customer_name: 'Unknown',
        nice_client_name: 'Unknown' },
      {
        value: 'Internal',
        nice_name: 'Within %client.name% Only',
        nice_customer_name: 'Within %client.name% Only',
        nice_client_name: 'Within %client.name%'
      },
      {
        value: 'CustomerCompany',
        nice_name: 'Within %story.customer.company.name%',
        nice_customer_name: 'With your co-workers',
        nice_client_name: 'Within their company'
      },
      {
        value: 'OtherCustomers',
        nice_name: 'With Other Customers',
        nice_customer_name: 'With other customers of %client.name%',
        nice_client_name: 'With other customers of %client.name%'
      },
      {
        value: 'Public',
        nice_name: 'Publicly',
        nice_customer_name: 'Publicly',
        nice_client_name: 'Publicly'
      }
    ];
  }

  public static niceNameForPermission(permission: string) {
    for (const perm of Utils.getPermissions()) {
      if (perm.value === permission) {
        return perm.nice_name;
      }
    }
  }

  public static getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce((obj, key) =>
      (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
  }

  public static customerAvatar(customer: Customer): string {
    if (!customer || !customer.avatarImage || !customer.avatarImage.url ||
      customer.avatarImage.url.trim().length === 0) {
      return '/assets/img/blank_avatar.jpg';
    }
    return customer.avatarImage.url;
  }

  public static memberAvatar = Utils.customerAvatar;

  public static customerCompanyImage(customer: Customer): string {
    if (!customer) {
      return this.companyImage(null)
    }
    return this.companyImage(customer.company);
  }

  public static memberCompanyImage = Utils.customerCompanyImage;

  public static companyImage(company: Company): string {
    if (!company || !company.image ||
      !company.image.url || company.image.url.trim().length === 0) {
      return '/assets/img/no_logo.png';
    }
    return company.image.url;
  }

  public static storyTypeIconClass(type): string {
    switch (type) {
      case 'Audio':
        return 'fa fa-volume-up';
      case 'Video':
        return 'fa fa-video';
      case 'Text':
        return 'fa fa-align-left';
      case 'Numeric':
        return 'fa fa-hashtag';
      case 'Link':
        return 'fa fa-link';
      case 'File':
        return 'fa fa-file';
      case 'Image':
        return 'far fa-image'
      case undefined:
        return 'far fa-eye';
    }
  }

  public static sentPromptStatus(sentPrompt: SentPrompt) {
    let daysLater;
    if (sentPrompt.later) {
      daysLater = Math.floor((new Date(sentPrompt.later).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    }
    if (sentPrompt.stories && sentPrompt.stories.length > 0) {
      let str = 'Responded';
      if (sentPrompt.stories[0].story && sentPrompt.stories[0].story.reviewStatus) {
        str += ' - Story ' + sentPrompt.stories[0].story.reviewStatus;
      }
      return str;
    } else if (sentPrompt.later) {
      if (daysLater < 1000) {
        return 'Deferred ' + daysLater + ' more days';
      } else {
        return 'Deferred Indefinitely';
      }
    } else {
      if (sentPrompt.status === 'sent') {
        return 'Sent';
      } else {
        return sentPrompt.status;
      }
    }
  }

  public static setFaviconForClient(client: Client) {
    if (client && client.favicon) {
      this.setFavicon(client.favicon.url);
    } else {
      this.setFavicon('/assets/slapfive-favicon.ico');
    }
  }

  public static setFavicon(url) {


    // TODO - removed to temporarily hotfix  #1336: need a better solution

    Array.from(document.getElementsByTagName('link')).forEach(element => {
      if (element.rel && element.rel.toLowerCase().indexOf('icon') > -1) {
        element.parentElement.removeChild(element);
      }
    });

    const link: HTMLLinkElement =
      document.querySelector('link[rel*=\'icon\']') as HTMLLinkElement || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  public static getCustomerTypes() {
    return ['Client', 'Employee', 'Partner', 'Thought Leader', 'Prospect', 'Former Client', 'Other'];
  }

  // generate a new GUID based filename for the CDN; putting it the right folder based on
  //  type of asset and currently running ENV
  public static generateCdnFilename(type?) {
    const URL = environment.google_storage_baseUrl;
    const filenameParam = '&name=' + cuid();
    return URL + filenameParam;
  }

  public static generateCdnUpload(filename:string) {
    const URL = environment.google_storage_baseUrl;
    const filenameParam = '&name=' + filename;
    return URL + filenameParam;
  }

  public static generateUUID() { // Public Domain/MIT
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); // use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:no-bitwise
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  public static cloneArray(arr) {
    return JSON.parse(JSON.stringify(arr));
  }

  public static cloneObject(obj) {
    if(!obj) {
      return {};
    }
    return JSON.parse(JSON.stringify(obj));
  }

  public static dateToInputString(date: Date): string {
    return (date.getFullYear().toString() + '-'
      + ('0' + (date.getMonth() + 1)).slice(-2) + '-'
      + ('0' + (date.getDate())).slice(-2))
      + 'T' + date.toTimeString().slice(0, 5);
  }

  public static daysFromNow(days: number): Date {
    return new Date((new Date()).valueOf() + 864E5 * days);
  }

  public static clientDateToServerDate(clientDate: any): any {
    return new Date(clientDate).toISOString();
  }

  public static justBeforeMidnightTonight() {
    return new Date(Utils.midnightTonight().getTime() - 1);
  }

  public static midnightTonight() {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    d.setHours(0, 0, 0, 0);
    return d;
  }

  public static cleanupDate(dt, start) {
    if (!dt) {
      return undefined;
    }
    const d = new Date(start ? Utils.midnightTonight() : Utils.justBeforeMidnightTonight());
    const d2 = new Date(dt);
    d2.setMinutes(d2.getMinutes() + d.getTimezoneOffset());
    d.setDate(d2.getDate());
    d.setMonth(d2.getMonth());
    d.setFullYear(d2.getFullYear());
    return d;
  }

  public static toDataFieldName(str: string) {
    const arr: string[] = [''];
    str.split('').forEach((c) => {
      if ('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.indexOf(c) > -1) {
        arr[arr.length - 1] = arr[arr.length - 1] + c;
      } else if (arr[arr.length - 1].length > 0) {
        arr.push('');
      }
    });
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].toLowerCase();
      if (i > 0) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
    }
    return arr.join('');
  }

  public static isAdmin(user: User) {
    return user.currentRole.permission_admin_menu_access || this.isSuperAdmin(user);
  }

  public static async checkAdmin(userService: CurrentUserService) {
    let user = await userService.getUser();
    return Utils.isAdmin(user);
  }

  public static async checkAdminOrRedirect(userService: CurrentUserService, router: Router) {
    if (await Utils.checkAdmin(userService)) {
      return true;
    } else {
      router.navigate(['/']);
      return false;
    }
  }

  public static isSuperAdmin(user: User) {
    return user.superadmin;
  }

  public static usersForUsersClient(user: User): User[] {
    const client = user.currentRole.client;
    const users: User[] = [];
    if (!client?.clientRoles) {
      return users;
    }
    client.clientRoles.map((clientRole) => {
      if (user.superadmin || clientRole.role !== 'HiddenAdmin') {
        clientRole['users'].map((u: ClientRole_User) => {
          if (u.user) {
            u.user.allowRequestAssignment = u.allowRequestAssignment;
            u.user.notifyNewPromptResponse = u.notifyNewPromptResponse;
            u.user.notifyNewRequests = u.notifyNewRequests;
            u.user.prefilterMembers = u.prefilterMembers;
            users.push(u['user']);
          }
        });
      }
    });
    return users;
  }

  public static selectRequestAWTD(request: any, member: any) {
    if (request && request.activityType_id) {
      member.selectedAWTD = member.activityTypesWillingToDo.find((awtd: any) => awtd.activityType && request.activityType_id === awtd.activityType.id);
    }
    return;
  }

  public static getTrustedContacts(member: FulfillmentCustomer | FulfillmentCustomer[]) {
    let trustedContacts = [];

    if (!Array.isArray(member)) {
      member = [member];
    }

    member.forEach((m) => {
      if (m.customer?.id) {
        m.customer.trustedContacts.forEach((tc) => {
          trustedContacts.push(tc.trusted_contact);
        });
        m.customer.company && m.customer.company.trustedContacts.forEach((tc) => {
          trustedContacts.push(tc.trusted_contact);
        });
      } else if (m.company?.trustedContacts) {
        m.company.trustedContacts.forEach((tc) => {
          trustedContacts.push(tc.trusted_contact);
        });
      }
    });

    trustedContacts = _.sortBy(_.uniqBy(trustedContacts.filter((tc) => tc?.id), 'id'), ['name']);

    trustedContacts.forEach((tc) => {
      if (tc) {
        tc.trustedBy = [];
        tc.trustedBy_members.forEach((tbm) => {
          tc.trustedBy.push(tbm.member);
        });
        tc.trustedBy_companies.forEach((tbc) => {
          tc.trustedBy.push(tbc.company);
        });
        tc.trustedBy = _.orderBy(tc.trustedBy, ['__typename', 'name'], ['desc', 'asc']);
      }
    });

    return trustedContacts;
  }

  public static calculateMemberAWTD(member: any) {
    if (!member || !member.activityTypesWillingToDo) {
      return;
    }
    member.RequestFulfillmentCustomers ||= [];
    member.pendingRequestCount = 0;
    member.activityTypesWillingToDo.map((todo: any) => {
      todo.requested = member.RequestFulfillmentCustomers.filter(
        (RFC: any) => todo.activityType && RFC.request.activityType && RFC.request.activityType.id === todo.activityType.id
      ).length;

      todo.completed = member.RequestFulfillmentCustomers.filter(
        (RFC: any) => {
          if (RFC.statusChanges.length > 0) {
            RFC.status = RFC.statusChanges[0].fulFillmentCustomerStatus;
            RFC.statusChangeDate = RFC.statusChanges[0].statusChangeDate;
          }

          return 'Completed' === RFC.status && RFC.request.activityType && todo.activityType && RFC.request.activityType.id === todo.activityType.id;
        }
      ).length;

      todo.declined = member.RequestFulfillmentCustomers.filter(
        (RFC: any) => 'Declined' === RFC.status && RFC.request.activityType && todo.activityType && RFC.request.activityType.id === todo.activityType.id
      ).length;

      todo.pending = todo.requested - todo.completed - todo.declined;

      if (todo.frequency && todo.completed > todo.frequency) {
        todo.status = 'danger';
      } else if (todo.frequency && todo.completed <= todo.frequency && (todo.completed + todo.pending) >= todo.frequency) {
        todo.status = 'warning';
      } else if (!todo.frequency || (todo.completed + todo.pending) < todo.frequency) {
        todo.status = 'success';
      }
    });

    member.pendingRequestCount = member.RequestFulfillmentCustomers.filter(
      (RFC: any) => ['Completed', 'Declined', 'Inactive'].indexOf(RFC.status) === -1
    ).length;

    member.closedRequestCount = member.RequestFulfillmentCustomers.filter(
      (RFC: any) => ['Completed', 'Declined', 'Inactive'].indexOf(RFC.status) !== -1
    ).length;
  }

  static setTrackingFilterDates(trackingFilterStartDate: Date, trackingFilterEndDate: Date) {
    this.setStorageValue('trackingFilterStartDate', JSON.stringify(trackingFilterStartDate));
    this.setStorageValue('trackingFilterEndDate', JSON.stringify(trackingFilterEndDate));
  }

  static getTrackingFilterStartDate(): Date {
    const st = this.getStorageValue('trackingFilterStartDate');
    return st ? JSON.parse(st) : undefined;
  }

  static getTrackingFilterEndDate(): Date {
    const end = this.getStorageValue('trackingFilterEndDate');
    return end ? JSON.parse(end) : undefined;
  }

  static setFilterDates(filterStartDate: Date, filterEndDate: Date, name = '', filterPeriod?: string, filterAmount?: number) {
    this.setStorageValue(name + 'FilterStartDate', JSON.stringify(filterStartDate));
    this.setStorageValue(name + 'FilterEndDate', JSON.stringify(filterEndDate));
    this.setStorageValue(name + 'FilterPeriod', JSON.stringify(filterPeriod));
    this.setStorageValue(name + 'FilterAmount', JSON.stringify(filterAmount));
  }

  static setFilterDateRange(name: string, range: object) {
    this.setStorageValue(name + 'FilterDateRange', JSON.stringify(range));
  }

  static getFilterStartDate(name = ''): Date {
    const st = this.getStorageValue(name + 'FilterStartDate');
    return st  ? JSON.parse(st) : undefined;
  }

  static getFilterEndDate(name = ''): Date {
    const end = this.getStorageValue(name + 'FilterEndDate');
    return end ? JSON.parse(end) : undefined;
  }

  static getFilterPeriod(name = ''): string {
    const period = this.getStorageValue(name + 'FilterPeriod');
    return period ? JSON.parse(period) : undefined;
  }

  static getFilterAmount(name = ''): number {
    const amount = this.getStorageValue(name + 'FilterAmount');
    return amount ? JSON.parse(amount) : undefined;
  }

  static getFilterId(name = ''): string {
    const id = this.getStorageValue(name + 'FilterId');
    return id ? JSON.parse(id) : undefined;
  }

  static getFilterDateRange(name = ''): object {
    const id = this.getStorageValue(name + 'FilterDateRange');
    return id ? JSON.parse(id) : undefined;
  }

  static setSearchFilter(search: string, name = '') {
    this.setStorageValue(name + 'SearchFilter', search);
  }

  static getSearchFilter(name = '') {
    const st = this.getStorageValue(name + 'SearchFilter');
    return st ? st : '';
  }

  static getUserStoryViewType(_default) {
    const type = this.getStorageValue('userStoryViewType');
    return type ? type : _default;
  }

  static setUserStoryViewType(type) {
    this.setStorageValue('userStoryViewType', type);
  }

  static setTableColumnViews(id: string, views: boolean[]) {
    this.setStorageValue(`tableViewsFor-${id}`, views.join(','));
  }

  static getTableColumnViews(id: string): boolean[] {
    const viewStr = this.getStorageValue(`tableViewsFor-${id}`);
    return viewStr && viewStr.split(',').map((view: string) => view === 'true');
  }

  static companyProgramStatuses() {
    return ['Targeted', 'Nominated', 'Invited', 'Rejected', 'Member', 'Former_Member'];
  }

  static companyActiveStatuses() {
    return ['Active', 'Paused', 'Inactive'];
  }

  static readOnlyUser(user: User) {
    if (!user || !user.currentRole || !user.currentRole.role) {
      alert("role not populated in currentUserRole");
      return true;
    }
    return user.currentRole.role == "Viewer";
  }

  static usersForClient(client: Client) {
    return client.clientRoles.flatMap(r => r.clientRoleUsers)
      .map(cru => {
        if (cru.user){
          cru.user.allowRequestAssignment = cru.allowRequestAssignment;
          cru.user.notifyNewPromptResponse = cru.notifyNewPromptResponse;
          cru.user.notifyNewRequests = cru.notifyNewRequests;
          cru.user.prefilterMembers = cru.prefilterMembers;
        }
        return cru.user
      })
      .filter((user: User) => user)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  static clientRole_User_FromCurrentUser(currentUser: User): ClientRole_User {
    return currentUser.clientRoles.find((clientRole_User: ClientRole_User) => {
      return clientRole_User.clientRole_id === currentUser.currentRole.id;
    });
  }

  static extendUser(currentUser: User): User {
    const user = JSON.parse(JSON.stringify(currentUser));
    user.clientRole_User = this.clientRole_User_FromCurrentUser(user);
    return user;
  }

  static fullHost() {
    const proto = window.location.protocol + '//';
    const host = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    return proto + host;
  }

  static compareLists(newList: String[], oldList: String[]) {
    const diffA = _.difference(newList, oldList);
    const diffB = _.difference(oldList, newList);
    const intersection = _.intersection(newList, oldList);

    return {
      newList: newList,
      oldList: oldList,
      add: diffA,
      drop: diffB,
      keep: intersection
    }
  }

  static compareObjects(newList: any[], oldList: any[], iteratee: String | Function = 'id') {
    const diffA = _.differenceBy(newList, oldList, iteratee);
    const diffB = _.differenceBy(oldList, newList, iteratee);
    const intersection = _.intersectionBy(newList, oldList, iteratee);
    const intersection2 = _.intersectionBy(oldList, newList, iteratee);

    return {
      newList: newList,
      oldList: oldList,
      add: diffA,
      drop: diffB,
      keep: intersection,
      keep2: intersection2
    }
  }

  static setExternalBaseUrl(client: Client) {
    if (!client.externalBaseUrl && client.opportunities) {
      const opportunity = client.opportunities.find((opp: Opportunity) => opp.url);
      if (opportunity) {
        const opportunityUrlArray = opportunity.url.split('/');
        opportunityUrlArray.pop();
        client.externalBaseUrl = opportunityUrlArray.join('/');
      }
    }
  }

  static trackScroll(scroller: string, target: string, container?: string) {
    const scrollerEl = $(scroller);
    const targetEl = $(target);

    if (scrollerEl[0] && targetEl[0]) {
      targetEl.show().css('top', (scrollerEl.offset().top + scrollerEl.height() - 1) +'px');

      if (container) {
        const containerEl = $(container);

        if (containerEl[0]) {
          const top = containerEl.offset().top;
          const bottom = top + containerEl.height();

          if (targetEl.offset().top < top + 50|| targetEl.offset().top > bottom) {
            targetEl.hide();
          }
        }

      }

    }
    return;
  }

  static updateActivityLogUrls(entity) {
    if (!entity.activityLogs) {
      return;
    }
    entity.activityLogs.forEach((log) => {
      if (log.notes) {
        log.notesDisplay = this.urlsToTags(log.notes);
      }
    })
  }


  static async companyNameExists(name: string) {
    const resp = await companyByNameGqlService.watch({name}).valueChanges.subscribe();
    console.log('getbyname', resp);
    return false;
  }

  public static urlsToTags(text) {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    text = text.replace("<", "&lt;");
    return text.replace(urlRegex, function (url, b, c) {
      var url2 = (c == 'www.') ? 'http://' + url : url;
      return '<a href="' + url2 + '" target="_blank">' + url + '</a>';
    });
  }

  static chartSplitter(arr: any, limit = 9) {
    const arrForSort = [...arr];
    arrForSort.sort((a,b) => b.value - a.value);
    arr = arrForSort.slice(0);
    let ret: any = [];
    if (arr.length <= limit) {
      ret = arr.slice(0);
    } else {
      const main = arr.slice(0, limit);
      const otherRaw = arr.slice(limit);
      const otherVal = otherRaw.reduce((n, {value}) => n + value, 0)
      main.push({ __typename: 'report_data', name: 'Other', value: otherVal })
      ret = main.slice(0);
    }

    return ret;
  }

  static initRolePermissions() {
    return {
      member_view: false,
      member_edit: false,
      company_view: false,
      company_edit: false,
      board_view: false,
      board_edit: false,
      story_view: false,
      story_edit: false,
      asset_view: false,
      asset_edit: false,
      prompt_view: false,
      prompt_edit: false,
      request_view: false,
      request_edit: false,
      activity_view: false,
      activity_edit: false,
      campaign_view: false,
      campaign_edit: false,
      strategic_initiative_view: false,
      strategic_initiative_edit: false,
      role: null,
    }
  }

  static async getRolePermissions(userService: CurrentUserService) {
    const user: User = await userService.getUser();
    const role = user.currentRole;

    return {
      member_view: role.permission_members == 'edit' || role.permission_members == 'view',
      member_edit: role.permission_members == 'edit',
      company_view: role.permission_companies == 'edit' || role.permission_companies == 'view',
      company_edit: role.permission_companies == 'edit',
      board_view: role.permission_boards == 'edit' || role.permission_boards == 'view',
      board_edit: role.permission_boards == 'edit',
      story_view: role.permission_stories == 'edit' || role.permission_stories == 'view',
      story_edit: role.permission_stories == 'edit',
      asset_view: role.permission_assets == 'edit' || role.permission_assets == 'view',
      asset_edit: role.permission_assets == 'edit',
      prompt_view: role.permission_prompts == 'edit' || role.permission_prompts == 'view',
      prompt_edit: role.permission_prompts == 'edit',
      request_view: role.permission_requests == 'edit' || role.permission_requests == 'view',
      request_edit: role.permission_requests == 'edit',
      activity_view: role.permission_activities == 'edit' || role.permission_activities == 'view',
      activity_edit: role.permission_activities == 'edit',
      campaign_view: role.permission_campaigns == 'edit' || role.permission_campaigns == 'view',
      campaign_edit: role.permission_campaigns == 'edit',
      strategic_initiative_view: role.permission_strategic_initiatives == 'edit' || role.permission_strategic_initiatives == 'view',
      strategic_initiative_edit: role.permission_strategic_initiatives == 'edit',
      role: role,
    }
  }

}
